.button {
  text-align: right;
}

.buttonCenter {
  text-align: center;
}

.newAddress {
  margin-top: 40px;
  border-top: 1px solid #999;
}

.or {
  width: 40px;
  margin: -13px auto auto auto;
  background-color: #fff;
  text-align: center;  
}
