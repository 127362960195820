.header {
  margin-bottom: 30px;
  text-align: center;
  font-size: 1em;
}

.bold {
  font-weight: bold;
  margin-left: 10px;
  font-size: 1.5em;
}

.date {
  margin-top: 10px;
  display: block;
  font-size: 1.2em;
}