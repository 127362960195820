@import '../../styles/Variables';

.info {
  background-color: $dark;
  margin-left: 10px;
  border-radius: 20px;
  width: 16px;
  height: 16px;
  display: inline-block;
  text-align: center;
  color: $white;
  font-size: 10px;
}

