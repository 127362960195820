@import "../../styles/Variables";

.rotate {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.button {
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.msrp {
    font-size: .95em;
    text-decoration: line-through;
}

.discount {
    color: $danger-dark;
    font-weight: 300;
    font-size: .8em;
}

.priceAdjustmentDisplay {
    font-weight: 600;
    font-size: 1.5em;
}

.tip {
    font-size: .8em;
    font-weight: 600;
    background-color: #000;
    color: $white;
    border-radius: 20px;
    width: 20px;
    display: inline-block;
    text-align: center;
}


// tool tip inventory
.toolTipInventory {
    display: block;
    width: 150px;
    
    .col-7 {
        text-align: left;
    }
}

.toolTipInventoryLine {
    border-top: 1px solid #fff;
    font-weight: 700;
}