$newColor: #464c58;
$themecolor: rgba(142, 162, 53, 1);
$themecolor-dark: rgba(142, 162, 53, 1);
$topbar: rgba(142, 162, 53, 1);
$sidebar:#fff;
$sidebar-text: rgba(142, 162, 53, 1);
$sidebar-icons: rgba(142, 162, 53, 1);
$sidebar-dark: #181c22;
$themecolor-alt: #464c58; //rgba(142, 162, 53, .75);
$grey: #ccc;
$black: #000;
// Login
.login {
    background-image: url('/img/login-bg.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
}

.alert-primary {
    background-color: $themecolor;
    h3 {
        color: $white !important;
    }
}


/*******************
/*Top bar
*******************/

.topbar {
    background: $topbar;
    .top-navbar .navbar-header .navbar-brand .dark-logo {
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo {
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
    }
    .navbar-light .navbar-nav .nav-item> {
        a.nav-link {
            color: rgba(255, 255, 255, 0.8)!important;
            &:hover,
            &:focus {
                color: $white!important;
            }
        }
    }
    &.is_stuck {
        position: fixed;
        top: 0;
        width: 100%;
    }
}

.logo-center .topbar {
    .navbar-header {
        background: transparent;
        box-shadow: none;
    }
    .top-navbar .navbar-header .navbar-brand .dark-logo {
        display: none;
    }
    .top-navbar .navbar-header .navbar-brand .light-logo {
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
    }
}

.headerPad {
    position: relative;
    width: 1590px;
    height: 70px;
    display: block;
    vertical-align: baseline;
    float: none;
}

@media(max-width: 768px) {
    .headerPad {
        display: none;
    }
}


/*******************
/*General Elements
*******************/

a {
    color: $themecolor;
}

a.link {
    &:hover,
    &:focus {
        color: $themecolor!important;
    }
}

.bg-theme {
    background-color: $themecolor !important;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: $themecolor;
    border-color: $themecolor;
}

.right-sidebar {
    .rpanel-title {
        background: $themecolor;
    }
}

.stylish-table {
    tbody tr {
        &:hover,
        &.active {
            border-left: 4px solid $themecolor;
        }
    }
}

.text-themecolor {
    color: $themecolor!important;
}

.profile-tab,
.customtab {
    li {
        a.nav-link {
            &.active {
                border-bottom: 2px solid $themecolor;
                color: $themecolor;
            }
            &:hover {
                color: $themecolor;
            }
        }
    }
}


/*******************
/*Buttons
*******************/

.btn-themecolor,
.btn-themecolor.disabled {
    background: $themecolor;
    color: $white;
    border: 1px solid $themecolor;
    &:hover {
        background: $themecolor;
        opacity: 0.7;
        border: 1px solid $themecolor;
    }
    &.active,
    &:focus {
        background: $themecolor-dark;
    }
}


/*******************
/*sidebar navigation
*******************/

.card-no-border .left-sidebar,
.card-no-border .sidebar-nav {
    background: $sidebar;
}

.mini-sidebar .sidebar-nav {
    background: transparent;
}

@media (min-width: 768px) {
    .mini-sidebar .sidebar-nav #sidebarnav>li>ul {
        background: $sidebar-dark;
    }
}

.sidebar-nav {
    background: $sidebar;
    ul {
        li {
            a {
                color: $sidebar-text;
                &.active,
                &:hover {
                    color: $themecolor-alt;
                }
                &.active {
                    color: $white;
                }
            }
            &.nav-small-cap {
                color: $muted;
            }
            svg {
                margin-right: 8px;
            }
        }
    }
    >ul>li {
        &.active>a,
        &.active:hover>a {
            color: $white!important;
            background: $themecolor-alt!important;
            i {
                color: $white!important;
            }
            &:after {
                border-color: $white;
            }
        }
    }
    >ul>li>a {
        &.active,
        &:hover {
            i {
                color: $themecolor-alt;
            }
        }
        i {
            color: $sidebar-icons;
        }
        &.active {
            font-weight: 400;
            background: $white;
            color: $themecolor;
        }
    }
}

.mini-sidebar {
    .sidebar-nav {
        #sidebarnav {
            >li {
                &:hover {
                    >a {
                        width: 260px;
                        background: rgba(142, 162, 53, 1);
                        color: #ffffff;
                        border-color: rgba(142, 162, 53, 1);
                    }
                }
            }
        }
    }
}

.user-profile .profile-text a {
    color: $sidebar-text!important;
}

.card-no-border .sidebar-footer {
    background: $sidebar-dark;
}

.btn-primary,
.label-primary {
    background-color: $themecolor;
    border-color: $themecolor;
    &:hover {
        background-color: transparentize($themecolor, .25);
        border-color: $themecolor;
    }
    &:disabled {
        background-color: $grey;
        border-color: $grey;
    }
}

.label-primary {
    background-color: $themecolor;
    border-color: $themecolor;
}


/* chat  */

.chat-list {
    li {
        .chat-time {
            display: inline-block;
            text-align: right;
            width: 140px;
            font-size: 13px;
            color: #99abb4;
        }
        .chat-content {
            width: calc(100% - 200px);
            display: inline-block;
            padding-left: 15px;
        }
    }
}


/* pagination */

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: rgba(142, 162, 53, 1);
    background-color: #fff;
    border: 1px solid #ddd;
}

.pagination>li>a:hover {
    background-color: transparentize($themecolor, .75);
    color: rgba(0, 0, 0, 1);
}

.page-item.active .page-link {
    z-index: 2;
    color: #fff;
    background-color: $themecolor;
    border-color: $themecolor;
}

.page-item.active .page-link:hover {
    z-index: 2;
    color: #fff;
    background-color: transparentize($themecolor, .25);
    border-color: transparentize($themecolor, .25);
}


/* Card */

.card-primary {
    background-color: $themecolor;
}

.rw-multiselect-tag {
    color: $white !important;
    background-color: $themecolor !important;
}


/* task list */

.userListIcons {
    img {
        margin-left: -20px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.dropzone {
    background: white;
    border: 3px dashed $themecolor;
    border-image: none;
    width: 100%;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    p {
        text-align: center;
        color: $themecolor;
        text-align: center;
        width: 100%;
    }
}

.dropzoneFileList {
    background: $themecolor-dark;
    color: $white;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;
    h5,
    a {
        color: $white;
    }
    h5 {
        border-bottom: 1px solid $white;
    }
}

.dropzoneFileListItem {
    border-bottom: 1px dotted $white;
}

// Alert
.s-alert-info {
    background: $themecolor;
    color: #fff;
}

.note-item {
    p {
        white-space: pre-line;
    }
}

.task-description {
    white-space: pre-line;
}

.task-box {
    border-radius: 4px;
    border: 0;
    background-color: rgba(0, 0, 0, .05);
    padding: 10px;
}

.cell-no-padding {
    padding: 4px !important;
}

// .rbc-month-row {
//   flex-basis: auto !important;
// }
.label-grey {
    background-color: $grey;
    margin-right: 3px;
    color: $black;
    padding: 2px;
}

.rbc-event-content {
    label {
        margin-bottom: 0 !important;
        font-size: 10px !important;
    }
    >span {
        font-size: 11px !important;
    }
}

.border-bottom {
    border-bottom: 1px solid $grey;
}

.mdi-spin {
    -webkit-animation: mdi-spin 2s infinite linear;
    animation: mdi-spin 2s infinite linear;
}

@-webkit-keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes mdi-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

// override
strong {
    font-weight: bold;
}