.filterContainer {
  display: flex;

  align-items: left;
}

.filterBlock {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #ccc;

  label {
    margin-right: 10px;
    font-size: 14px;
    display: block;
  }

  &:last-child {
    margin-right: 0;
    border: none;
  }
}

.filterButton {
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 300;
  color: #333;
}


.filterItemBlock {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
    text-align: center;
  }
}

.filterItem {
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.badge { margin-right: 4px; text-transform: uppercase; }