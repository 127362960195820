@import "../../styles/Variables";

.badge {
  margin-right: 0.5rem;
}


.untouch {
  color: #fff;
  border-radius: 20px;
  background-color: $danger;
  padding: 2px;
}

.touch {
  color: #fff;
  border-radius: 20px;
  background-color: $themecolor;
  padding: 2px;
}


.notes {
  text-align: left;
  display: block;
}