.dataTable {
    div {
        border-bottom: 1px solid #e0e0e0;
        &:last-child {
            text-align: right;
        }
    }
}

.year {
    font-weight: bold;
    font-size: 22px;
    text-align: center;
}