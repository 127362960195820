@import '../../styles/Variables';

.inactive {
    opacity: 0.35;
    .sectionHeader {
        margin-bottom: 0;
    }
}

.cashAccountHeader {
    font-size: 14px;
    font-weight: normal;
    font-style: italic;

    span {
        font-weight: bold;
        font-size: 13x;
        color: $primary;
    }
}

.buttonDisabled {
    cursor: not-allowed !important;
}
.buttonActive {
    cursor: pointer !important;
}

.button {
    &:disabled {
        background-color: $muted !important;
        border: $muted !important;
        color: $white !important;
    }
}

.terms {
    font-style: italic;
    margin-top: 20px;
}

.quoteFormBackButton {
    display: flex;
    align-items: right;
    justify-content: right;
    margin-bottom: 20px;
}

.bold {
    font-weight: 600;
}