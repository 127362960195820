.button {
  display: block;
  width: 100%;
  border: none;
  border-radius: 20px;
  background-color: transparent;
  padding: 0.5rem;

  &:hover {
    background-color: #f6f6f6;
  }
}

.bold {
  font-weight: 700;
}

.badge {
  text-transform: uppercase;
  display: block !important;
}


.date {
  font-size: 0.8rem;
  color: #999;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}