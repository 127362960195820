.dropArea {
    height: 75px;
    border: 2px dashed #ccc;
    display: flex;
    padding: 20px 40px;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    p {
        margin-bottom: 0;
    }
}