.actionFooter {
  display: block !important;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.centerButton {
  text-align: center;
}
.button {
  width: 80%;
}

.rowHeader {
  font-weight: bold;
}
.rowHeaderEnd {
  font-weight: bold;
  text-align: right;
}

.rowFooter {
  font-weight: bold;
  text-align: right;
}

.smallText {
  font-size: .9em;
  line-height: 1.8em;
  margin: 1em auto 0 auto;
  max-width: 80%;
  text-align: center;
}

.saving {
  text-align: center;
}


@media (min-width: 768px) {
  .button {
    width: 50%;
  }
}