@import '../styles/Variables';

.header {
  background-color: $muted !important;
  color: $white !important;
}

.headerGrey {
  background-color: $extra-light !important;
  color: #999 !important;
}