@import '../../styles/Variables.scss';

.spacing {
  margin-right: 20px;
}

.external {
  border-left: 5px solid $primary;
}

.internal {
  border-left: 5px solid $success;
}

.messageRow {
  margin-bottom: 20px;
}

.message {
  margin-left: 20px;
}
.header1 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}