@import '../../styles/Variables';

.header {
  font-weight: 700;
}

.nav {
  margin-bottom: 20px;
}

.approved {
  color: $megna;
}
.buttonApproved {
  background: transparent;
  border: none;
  color: $megna
}

.reject {
  color: $danger;
}
.buttonReject {
  background: transparent;
  border: none;
  color: $danger
}


.imageContainer {
  position: relative;
  width: 100%;
  text-align: center;
}

.image {
  max-width: 100%;
  margin: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}


.actionRow {
  width: 100%;
}