@import "../../styles/Variables.scss";


@mixin box {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 10px;
  padding: 20px 10px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.totalQuotes {
  @include box;
  background-color: $primary;
  color: $white;

  span {
    display: block;
    font-size: 0.9rem;
  }
}

.total {
  @include box;
  background-color: $success;
  color: $white;

  span {
    display: block;
    font-size: 0.9rem;
  }
}

.average {
  @include box;
  background-color: $info;
  color: $white;

  span {
      display: block;
      font-size: 0.9rem;
    }
}

.rate {
  @include box;
  background-color: $warning;
  color: $white;

  span {
    display: block;
    font-size: 0.9rem;
  }
}

.ae {
  @include box;
  background-color: $inverse;
  color: $white;

  span {
    display: block;
    font-size: 0.9rem;
  }
}


.touch {
  @include box;
  background-color: $inverse-dark;
  color: $white;

  span {
    display: block;
    font-size: 0.9rem;
  }
}