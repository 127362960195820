.cbp_tmtimeline {
  margin: 30px 0 0 0;
  padding: 0;
  list-style: none;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 5px;
    background: #f5f5f6;
    left: 20%;
    margin-left: -6px;
  }
  >li {
    position: relative;
    &:first-child {
      .cbp_tmtime {
        span.large {
          color: #444;
          font-size: 17px !important;
          font-weight: 700;
        }
      }
      .cbp_tmicon {
        // background: #fff;
        // color: #666;
      }
    }
    &:nth-child(odd) {
      // .cbp_tmtime {
      //   span {
      //     &:last-child {
      //       color: #444;
      //       font-size: 12px;
      //     }
      //   }
      // }
      .cbp_tmlabel {
        background: #f5f5f6;
        &:after {
          border-right-color: #f5f5f6;
        }
      }
    }
    .empty {
      span {
        color: #777;
      }
    }
    .cbp_tmtime {
      display: block;
      width: 23%;
      padding-right: 100px;
      position: absolute;
      line-height: 40px;
      span {
        display: block;
        text-align: right;
        // &:first-child {
        //   font-size: 16px;
        //   color: #3d4c5a;
        //   font-weight: 700;
        // }
        // &:last-child {
        //   font-size: 12px;
        //   color: #444;
        // }
      }
    }
    .cbp_tmlabel {
      margin: 0 0 15px 25%;
      background: #f5f5f6;
      color: #fff;
      padding: 2em;
      font-size: 1.2em;
      font-weight: 300;
      line-height: 1.4;
      position: relative;
      border-radius: 5px;
      &:hover {
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        transition: all 150ms linear;
      }
      &:after {
        right: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-right-color: #f5f5f6;
        border-width: 10px;
        top: 10px;
      }
      blockquote {
        background: #fff;
        color: #444;
        font-size: 16px;
      }
      .map-checkin {
        border: 5px solid rgba(235, 235, 235, 0.2);
        -moz-box-shadow: 0px 0px 0px 1px #ebebeb;
        -webkit-box-shadow: 0px 0px 0px 1px #ebebeb;
        box-shadow: 0px 0px 0px 1px #ebebeb;
        background: #fff !important;
      }
      h2 {
        margin: 0px;
        padding: 0 0 10px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        line-height: 26px;
        color: #222;
        a {
          color: #303641;
          font-size: 17px;
          &:hover {
            text-decoration: none;
          }
        }
        span {
          font-size: 17px;
          color: #666;
          font-weight: normal;
        }
      }
      p {
        color: #444;
      }
    }
    .cbp_tmicon {
      width: 40px;
      height: 40px;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      font-size: 1.4em;
      line-height: 40px;
      -webkit-font-smoothing: antialiased;
      position: absolute;
      color: #fff;
      background: $themecolor; // #46a4da;
      border-radius: 50%;
      box-shadow: 0 0 0 5px #f5f5f6;
      text-align: center;
      left: 20%;
      top: 0;
      margin: 0 0 0 -25px;
    }
  }
}


/* Example Media Queries */

@media screen and (max-width: 65.375em) {
  .cbp_tmtimeline>li .cbp_tmtime span:last-child {
    font-size: 12px;
  }
}

@media screen and (max-width: 47.2em) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline {
    >li .cbp_tmtime {
      width: 100%;
      position: relative;
      padding: 0 0 20px 0;
      span {
        text-align: left;
      }
    }
    >li .cbp_tmlabel {
      margin: 0 0 30px 0;
      padding: 1em;
      font-weight: 400;
      font-size: 95%;
    }
  }
  .cbp_tmtimeline>li .cbp_tmlabel:after {
    right: auto;
    left: 20px;
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
    top: -20px;
  }
  .cbp_tmtimeline>li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: #f5f5f6;
  }
  .cbp_tmtimeline>li .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: -55px 5px 0 0px;
  }
}